<!--
 * @Description: 店铺管理 > 提现明细
 * @Author: zhaobin
 * @Date: 2023-02-24 10:12:38
 * @LastEditors: goodLiang
 * @LastEditTime: 2024-02-29 10:51:18
-->
<script>
import { withDrawalList } from '@/api/shop'
import { asynchronousExportOfWithdrawalDetails } from '@/api/jiushi'
import ExportLog from './components/ExportLog.vue'

export default {
  name: 'shop-cash-details',
  components: { ExportLog },
  data() {
    return {
      user_id: this.$route.params.id,
      page_index: 1,
      page_size: 20,
      total: 0,
      tableData: [],
      form: {
        time: []
      }
    }
  },
  computed: {
    exportParams() {
      return {
        start_create_time: this.form.time && this.form.time[0],
        end_create_time: this.form.time && this.form.time[1],
        seller_id: this.user_id
      }
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    exportApi() {
      return asynchronousExportOfWithdrawalDetails
    },
    search(keep) {
      if (!keep) this.page_index = 1
      const params = {
        page_index: this.page_index,
        page_size: this.page_size,
        seller_id: this.user_id
      }
      this.appendPostData(params, this.form)
      withDrawalList(params).then((res) => {
        const { data } = res
        if (!data) return
        this.total = res.page_count
        this.tableData = data
      })
    },
    resetForm() {
      this.$refs.searchForm.resetFields()
      this.search()
    },
    appendPostData(postParams, obj) {
      for (const i in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, i)) {
          switch (i) {
            case 'time':
              if (obj[i]) {
                postParams.start_create_time = obj[i][0]
                postParams.end_create_time = obj[i][1]
              }
              break
            default:
              if (obj[i] !== '') {
                postParams[i] = obj[i]
              }
          }
        }
      }
    }
  }
}
</script>
<template>
  <div class="app-container">
    <CustomCard>
      <FoldPanel>
        <el-form
          :model="form"
          ref="searchForm"
          label-position="left"
          label-width="110px"
        >
          <el-row>
            <GridItem>
              <el-form-item label="日期">
                <el-date-picker
                  v-model="form.time"
                  type="datetimerange"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </el-form-item>
            </GridItem>
          </el-row>
        </el-form>
        <template #operation>
          <el-button type="primary" @click="search()">筛选</el-button>
          <el-button @click="resetForm">清空</el-button>
        </template>
      </FoldPanel>
      <el-divider></el-divider>
      <div class="table-box">
        <export-log
            v-if="total"
            style="margin-bottom: 10px;"
            export_type="MERCHANT_PLATFORM_WITHDRAWAL_DETAILS"
            :params="exportParams"
            :page_index="this.page_index"
            :page_size="this.page_size"
            :api="exportApi()"
            :list_type="5"
          />
        <el-table
          :data="tableData"
          border
          :header-cell-style="{
            'text-align': 'center',
            background: '#fafafa',
            color: '#444'
          }"
          :cell-style="{ 'text-align': 'center' }"
        >
          <el-table-column
            type="index"
            label="序号"
            width="80"
          ></el-table-column>
          <el-table-column label="日期" prop="create_time"></el-table-column>
          <el-table-column
            label="提现到账时间"
            prop="finish_time"
          ></el-table-column>
          <el-table-column label="金额（元）" v-slot="scope">
            ￥{{ $filters.formatPrice(scope.row.order_amount) }}
          </el-table-column>
          <el-table-column label="提现手续费（元）" v-slot="scope">
            ￥{{ $filters.formatPrice(scope.row.fee) }}
          </el-table-column>
          <el-table-column label="到账金额" v-slot="scope">
            ￥{{ $filters.formatPrice(scope.row.receive_amount) }}
          </el-table-column>
          <el-table-column label="状态" v-slot="scope">
            <div v-if="scope.row.status == 'SUCCESS'">提现成功</div>
            <div v-else-if="scope.row.status == 'FAIL'">
              提现失败({{ scope.row.fail_reason }})
            </div>
            <div v-else>审核中</div>
          </el-table-column>
          <el-table-column
            label="到账银行卡"
            prop="bank_name"
          ></el-table-column>
          <el-table-column label="提现流水号" prop="order_no"></el-table-column>
          <el-table-column label="操作人" prop="staff_name"></el-table-column>
        </el-table>
      </div>
      <template #bottomOperation
        ><el-pagination
          @size-change="search()"
          @current-change="search('keep')"
          v-model::current-page="page_index"
          :page-sizes="[20, 50, 100]"
          v-model::page-size="page_size"
          layout="total, prev, pager, next, sizes"
          :total="total"
        ></el-pagination>
      </template>
    </CustomCard>
  </div>
</template>
